.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Make it fixed to cover the full screen */
  top: 0;
  left: 0;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  z-index: 9999; /* Ensure it's on top of everything */
}

.spinner {
  border: 6px solid var(--clr-bg-alt); /* Light background */
  border-top: 6px solid var(--clr-primary); /* Primary color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
