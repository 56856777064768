.about__details-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2em; /* Space between details and links */
  text-align: left;
}

.about__details-list li {
  display: flex;
  align-items: center;
  margin-bottom: 0.8em; /* Space between each detail */
}

.about__details-list li svg {
  margin-right: 0.5em;
}

.about__links {
  list-style-type: none;
  padding: 0;
  text-align: left; /* Text aligned left by default */
}

.about__links li {
  margin-bottom: 0.5em;
}

.about__links a {
  text-decoration: none;
  color: var(--clr-primary); /* Adjust color as per your theme */
}

.about__links a:hover {
  /* Remove underline on hover */
  text-decoration: none;
}

.about__details {
  margin-left: 3em; /* Add space between the image/links and the text */
}

.about__name {
  color: var(--clr-primary);
}

.about__role {
  margin-top: 1.2em;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
}

.about__desc a {
  color: var(--clr-primary);
}

@media (max-width: 600px) {
  .about__details {
    margin-left: 0;
    text-align: center; /* Center the text */
  }

  .about__image-links {
    align-items: center; /* Center image and lists in media view */
  }

  .about__details-list {
    text-align: center; /* Center the personal details */
  }

  .about__links {
    text-align: center; /* Center the social links */
  }

  .about__image {
    width: 150px;
    height: 150px;
  }
}
