.timeline {
  position: relative;
  padding: 2em 0;
  max-width: 900px;
  margin: 0 auto;
}

.timeline__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 3em;
  position: relative;
}

.timeline__marker {
  background-color: var(--clr-primary);
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.timeline__content {
  background-color: var(--clr-bg-alt);
  padding: 1.5em;
  border-radius: 8px;
  box-shadow: var(--shadow);
  margin-left: 4em; /* Space between the marker and the content */
  width: 100%;
  position: relative;
}

.timeline__line {
  position: absolute;
  left: 0.75em; /* Center the line relative to the marker */
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: var(--clr-primary);
}

.timeline__item h3 {
  color: var(--clr-primary);
  margin-bottom: 0.5em;
}

.timeline__item p {
  margin: 0.5em 0;
}

.timeline__item ul {
  list-style-type: disc;
  margin-top: 0.8em;
  padding-left: 1.5em;
}

.timeline__item ul li {
  margin-bottom: 0.5em;
}

/* Mobile and smaller screens */
@media (max-width: 768px) {
  .timeline__item {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2.5em;
  }

  .timeline__marker {
    left: 50%; /* Center the marker */
    transform: translateX(-50%);
  }

  .timeline__line {
    display: none; /* Hide the line on mobile */
  }

  .timeline__content {
    margin-left: 0;
    margin-top: 1.5em;
    width: 100%;
    text-align: left;
  }
}

@media (max-width: 600px) {
  .timeline__content {
    padding: 1em;
  }

  .timeline__marker {
    width: 1.2em;
    height: 1.2em;
  }
}
