.blogs {
  max-width: 900px;
  margin: 0 auto;
  padding: 2em 0;
}

.blogs__list {
  list-style-type: none;
  padding: 0;
}

.blog__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2em;
}

.blog__image-container {
  flex: 0 0 150px; /* Fixed width for the image */
  margin-right: 1.5em;
}

.blog__image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.blog__content {
  flex: 1;
}

.blog__title {
  font-weight: bold;
  font-size: 1.2em;
  color: var(--clr-primary);
  text-decoration: none;
}

.blog__title:hover {
  text-decoration: underline;
}

.blog__date {
  font-size: 0.9em;
  color: var(--clr-fg-alt);
  margin-top: 0.5em;
  margin-bottom: 0.8em;
}

.blog__description {
  font-size: 1em;
  color: var(--clr-fg);
}

.load-more-btn {
  display: inline-block;
  padding: 0.8em 1.6em;
  font-size: 1em;
  color: var(--clr-primary);
  background-color: var(--clr-bg-alt);
  border: 2px solid var(--clr-primary);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  margin-top: 2em;
  text-transform: uppercase;
}

.load-more-btn:hover {
  background-color: var(--clr-primary);
  color: var(--clr-bg);
}

.load-more-btn:focus {
  outline: none;
}

/* Responsive design */
@media (max-width: 600px) {
  .blog__item {
    flex-direction: column;
    align-items: center;
  }

  .blog__image-container {
    margin-right: 0;
    margin-bottom: 1em;
  }

  .blog__image {
    width: 100%;
    height: auto;
  }

  .blog__content {
    text-align: center;
  }
}
